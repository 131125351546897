export const camelCaseToText = (data) => {
    let text = [...data];
    let result= [];

    text.forEach((char) =>  {
        if (isUpperCase(char)) {
            result.push(" ");
            result.push(char.toLowerCase());
        } else result.push(char);
    });

    return result.join("");
}

const isUpperCase = (char) => /^[A-Z]$/.test(char);