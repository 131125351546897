export const LANGUAGE_OPTIONS = ['Indonesia', 'English'];
export const GENRE_OPTIONS = ['Adventure', 'Science Fiction', 'Fabel', 'Realis'];
export const AGE_RANGE_OPTIONS = ['4 - 8 Years Old', '6 - 9 Years Old', '8 - 12 Years Old'];
export const ISLAMIC_VALUE = [
    'Bersyukur', 
    'Kejujuran', 
    'Allah Sang Pencipta', 
    'Ibadah Kepada Allah', 
    'Amal', 
    'Doa Sehari-hari'
];