import { camelCaseToText } from "./helper";

export const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
export const getFormErrorMessage = (meta) => {    
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
}

export const validateForm = (data, fieldAttribute, customeValidate=[]) => {
    let errors = {};
    let requriedField = Object.keys(fieldAttribute);

    requriedField.forEach((field) => {
        if (!data[field] || data[field] === null) errors[field] = `${camelCaseToText(field)} is required`;
    });

    if (customeValidate.length > 0){
        customeValidate.forEach((validate) => {
            if (validate.condition) errors[`${validate.field}`] = validate.message;
        })
    }

    return errors;
  }