import React, { useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Field, Form } from 'react-final-form';
import { getFormErrorMessage, validateForm } from '../Hooks/form';
import { AGE_RANGE_OPTIONS, GENRE_OPTIONS, LANGUAGE_OPTIONS } from '../Constant/general';
import { InputText } from 'primereact/inputtext';
import { GEMINI_KEY_API, postURLData } from '../Hooks/api';

const Dashboard = () => {
  const toast = useRef(null);
  const [ loading, setLoading ] = useState(false);
  const initialValues = {
    topic:'',
    maxParagraph: 0,
    language: '',
    genre: '',
    age: '',
    islamicValue: ''
  };

  const [ aiStoryGenerator, setAiStoryGenerator ] = useState("");

  const generateStory = (data, form) => {
    setLoading(true);

    postURLData(`https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash:generateContent?key=${GEMINI_KEY_API}`, {
        contents: { 
            parts: [{
                text:  `Buatkan cerita anak dengan topik ${data.topic} menggunakan bahasa ${data.language} sebanyak ${data.maxParagraph} paragraf dengan target pembaca usia ${data.age}, genre cerita adalah ${data.genre} serta sisipkan nilai keislamant yaitu ${data.islamicValue}.`,
            }]
        }
    })
    .then((response) => {
        const responseAPI = response.data;
        const result = responseAPI.candidates[0].content.parts[0].text;
        setAiStoryGenerator(result);
        form.restart();
        setLoading(false);
    })
    .catch((error)=> {
        toast.current.show({
            severity: 'error',
            summary: "Generate Failed",
            detail: error.response,
            life: 3000
        });
        setLoading(false);
    });
  }

  return (
    <>
        <Toast ref={toast}/>
        <Card>
            <h3 className='m-0 p-0'>Hai Guyss, welcome to Story AI Generator by Edufic</h3> 
        </Card>
        <Card className='mt-3'>
            <Form
                initialValues={initialValues}
                validate={(data) => validateForm(data, initialValues)}
                onSubmit={generateStory}
                render={({handleSubmit, form}) => (
                    <form onSubmit={handleSubmit} className='flex flex-column gap-3 p-fluid'>
                        <Field
                            name='topic'
                            render={({input, meta}) => (
                                <div className='flex-auto'>
                                    <label className='font-bold block mb-2'>Topic</label>
                                    <InputText
                                        id='topic'
                                        name='topic'
                                        {...input}
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                        <Field
                            name='maxParagraph'
                            render={({input, meta}) => (
                                <div className='flex-auto'>
                                    <label className='font-bold block mb-2'>Max Paragraph</label>
                                    <InputNumber
                                        id='maxParagraph'
                                        name='maxParagraph'
                                        value={input.value}
                                        onValueChange={(e) => input.onChange(e.value)}
                                        onBlur={input.onBlur}
                                        max={40}
                                        min={0}
                                        placeholder='Set max paragraph for your story'
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                        <Field
                            name='language'
                            render={({input, meta}) => (
                                <div className='flex-auto'>
                                    <label className='font-bold block mb-2'>Language</label>
                                    <Dropdown
                                        id='language'
                                        name='language'
                                        {...input}
                                        options={LANGUAGE_OPTIONS}
                                        placeholder='Set language for your story'
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                        <Field
                            name='genre'
                            render={({input, meta}) => (
                                <div className='flex-auto'>
                                    <label className='font-bold block mb-2'>Genre</label>
                                    <Dropdown
                                        id='genre'
                                        name='genre'
                                        {...input}
                                        options={GENRE_OPTIONS}
                                        placeholder='Set genre for your story'
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                        <Field
                            name='age'
                            render={({input, meta}) => (
                                <div className='flex-auto'>
                                    <label className='font-bold block mb-2'>Age Range</label>
                                    <Dropdown
                                        id='age'
                                        name='age'
                                        {...input}
                                        options={AGE_RANGE_OPTIONS}
                                        placeholder='Set age range for your story'
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                        <Field
                            name='islamicValue'
                            render={({input, meta}) => (
                                <div className='flex-auto'>
                                    <label className='font-bold block mb-2'>Islamic Value</label>
                                    <InputText
                                        id='islamicValue'
                                        name='islamicValue'
                                        {...input}
                                        disabled={loading}
                                    />
                                    {getFormErrorMessage(meta)}
                                </div>
                            )}
                        />
                        <div className='flex gap-1 lg:justify-content-center md:justify-content-center sm:justify-content-center justify-content-end'>
                            <Button label='Cancel' type='button' size='small' severity='danger' outlined className='w-max' onClick={() => form.restart()} loading={loading}/>
                            <Button label='Generate' icon='pi pi-cog' size='small' autoFocus className='w-max' loading={loading}/>
                        </div>
                    </form>
                )}
            />
        </Card>
        <Card className='mt-3'>
            <h3 className='m-0 p-0 text-center'>Result</h3>
            <div className='flex justify-content-center align-items-center'>
                {loading ? <ProgressSpinner style={{width: '200px', height: '200px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> : <p>{aiStoryGenerator}</p>}
            </div>
        </Card>
    </>
  )
}

export default Dashboard
